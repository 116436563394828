// styles
import '@scss/app-kildonan.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-lightbox/dist/slick-lightbox.css'
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css'
import 'plyr/dist/plyr.css'

//import $ from 'jquery'

window.$ = $
window.jQuery = $

import('@js/old/main.js')

// required for HMR to work
if (import.meta.hot) {
    import.meta.hot.accept()
}
